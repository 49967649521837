import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import { Link } from 'gatsby'
import termsImg from '../../assets/images/terms-of-service.jpg'

const OtherPolicies = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Other Policies" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Other Policies" 
            />
            <section className="terms-of-service-area ptb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="terms-of-service-content">
                                <img src={termsImg} alt="tt" />
                                <p><i>Last updated on May 2024.</i></p>
                                <h3>1. Ownership: You own and control your data</h3>
                                <p>We do not share your data with other companies</p>
                                <p>You control how long your data is retained</p>
                                <p>You own your inputs and outputs</p>
                                <h3>2. Access Control</h3>
                                <p>Enterprise-level authentication through SAML SSO.</p>
                                <p>Fine-grained control over access and available features.</p>
                                <p>Custom models are yours alone to use and are not shared with other entities.</p>
                                <p>Data encryption at rest (AES-256) and in transit (TLS 1.2+)</p>
                                <h3>3. Model Training</h3>
                                <p>Custom models are trained on your data alone.</p>
                                <h3>4. Data Storage & Archiving</h3>
                                <p>Encrypted backups stored.</p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                        <aside className="widget-area">
                                <div className="widget widget_insight">
                                    <ul>
                                        <li>
                                            <Link to="/legal/terms-of-use">
                                                Terms of Use
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/legal/privacy-policy">
                                                Privacy Policy
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/legal/cookie-policy">
                                                Cookie Policy
                                            </Link>
                                        </li>
                                        <li className='active'>
                                            <Link to="/legal/other-policies">
                                                Other Policies
                                            </Link>
                                        </li>
                                    </ul>
                                </div>

                                <div className="widget widget_tag_cloud">
                                    <h3 className="widget-title">Popular Tags</h3>

                                    <div className="tagcloud">
                                    <Link to="/about-us">What is Neolytics? <span className="tag-link-count"> (3)</span></Link>
                                    <Link to="/contact">how do I contact Neolytics? <span className="tag-link-count"> (3)</span></Link>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </Layout>
    )
}

export default OtherPolicies;